<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="images"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="imagesPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Images</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('image_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Image
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- img -->
                          <div v-if="editedIndex !== -1">
                            <v-img
                              alt="img"
                              class="img"
                              lazy-src="https://via.placeholder.com/150"
                              :src="editedImage.url"
                              max-width="150"
                              contain
                            />
                          </div>

                          <!-- Files -->
                          <v-col class="mt-n5" cols="12">
                            <v-file-input
                              v-model="files"
                              :rules="filesRules"
                              :multiple="multiple"
                              counter
                              show-size
                              placeholder="Click here to select files"
                            ></v-file-input>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this image?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="btn btn-primary" @click="deleteImageConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- img -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.location="{ item }">
            <div v-if="item.name.split('.').pop() == 'pdf'">
              <v-img
                alt="img"
                class="img my-1"
                src="@/assets/img/pdf/pdf-thumbnail.png"
                lazy-src="https://via.placeholder.com/150"
                max-width="50"
                max-height="60"
                contain
              />
            </div>

            <div v-else>
              <div v-if="item.thumbnail_url != null">
                <v-img
                  alt="img"
                  class="img my-1"
                  :src="item.thumbnail_url"
                  lazy-src="https://via.placeholder.com/150"
                  max-width="50"
                  max-height="60"
                  contain
                />
              </div>

              <div v-else>
                <v-img
                  alt="img"
                  class="img my-1"
                  :src="item.url"
                  lazy-src="https://via.placeholder.com/150"
                  max-width="50"
                  max-height="60"
                  contain
                />
              </div>
            </div>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('image_update')"
              small
              class="mr-2"
              @click="editImage(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('image_delete')"
              small
              @click="deleteImage(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Images per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="10000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementImages",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,
      multiple: true,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "Img", value: "location" },
        { text: "Name", value: "name" },
        { text: "Url", value: "url" },
        { text: "Document Id", value: "document_id" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      images: [],
      editedIndex: -1,
      editedImage: {
        id: 0,
        name: "",
        location: "",
        url: "",
        document_id: 0,
        created_at: "",
        updated_at: "",
      },
      defaultImage: {
        id: 0,
        name: "",
        location: "",
        url: "",
        document_id: 0,
        created_at: "",
        updated_at: "",
      },

      // files
      files: null,
      filesRules: [(v) => !!v || "Required"],

      page: 1,
      imagesPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1 ? "New Image" : "Edit Image";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedImages();
    },

    // imagesPerPage
    imagesPerPage() {
      this.getApiPagedImages();
    },

    // perPageChoice
    perPageChoice(val) {
      this.imagesPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("image_access")) {
      this.$router.push("/dashboard/inbox");
    }

    // this.getApiImages();
    this.getApiPagedImages();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiImage();
        } else {
          this.createApiImage();
        }
      }
    },

    // editImage
    editImage(image) {
      this.editedIndex = this.images.indexOf(image);
      this.editedImage = Object.assign({}, image);
      this.dialog = true;

      /* if (this.editedIndex == -1) {
        this.multiple = true;
      } else {
        this.multiple = false;
      } */
    },

    // deleteImage
    deleteImage(image) {
      this.editedIndex = this.images.indexOf(image);
      this.editedImage = Object.assign({}, image);
      this.dialogDelete = true;
    },

    // deleteImageConfirm
    deleteImageConfirm() {
      this.deleteApiImage();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedImage = Object.assign({}, this.defaultImage);
        this.editedIndex = -1;
      });

      this.$refs.form.reset();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedImage = Object.assign({}, this.defaultImage);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // Get Api Images
    async getApiImages() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("images");

      if (res.status == 200) {
        this.images = res.data;
      }

      this.overlay = false;
    },

    // Get Api Paged Images
    async getApiPagedImages() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `images/paged?page_no=${this.page}&page_size=${this.imagesPerPage}`
      );

      if (res.status == 200) {
        this.images = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // Create Api Image
    async createApiImage() {
      try {
        this.overlay = true;

        // let formData = new FormData();
        // formData.append("file", this.files);

        let formData = new FormData();

        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i];

          // console.log(i);

          formData.append("files[" + i + "]", file);
        }

        let res = await this.axios.post(
          "images/document-with-image",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // console.log(res);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Image Created Successfully!",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          let data = res.data;

          let imagesApi = data.images;

          this.images.push(...imagesApi);

          this.files = null;

          this.close();
        }
      } catch (error) {
        let status = error.response.status;
        let data = error.response.data;

        if (status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (status == 400 || status == 422) {
          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Update Api Image
    async updateApiImage() {
      try {
        this.overlay = true;

        var formData = new FormData();

        for (var i = 0; i < this.files.length; i++) {
          var file = this.files[i];

          // console.log(i);

          formData.append("files[" + i + "]", file);
        }

        const res = await this.axios.post(
          `images/${this.editedImage.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // console.log(res);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Image Updated Successfully!",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.close();

          Object.assign(this.images[this.editedIndex], res.data);

          this.files = null;
        }
      } catch (error) {
        let status = error.response.status;
        let data = error.response.data;

        if (status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (status == 400 || status == 422) {
          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Delete Api Image
    async deleteApiImage() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `images/${this.editedImage.id}`,
        "Image Deleted Successfully!"
      );

      if (res.status == 200) {
        this.images.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },

    // setBase64
    setBase64(base64, name) {
      return this.$helpers.setBase64(base64, name);
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
